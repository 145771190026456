body,html{
 background-color: #1B1B1D;
}

#root {
  background-color: #1B1B1D;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes ldio-wag2vezkqai {
  0% {
    transform: rotate(0deg)
  }

  50% {
    transform: rotate(180deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

.ldio-wag2vezkqai div {
  position: absolute;
  animation: ldio-wag2vezkqai 1s linear infinite;
  width: 91.19999999999999px;
  height: 91.19999999999999px;
  top: 11.399999999999999px;
  left: 11.399999999999999px;
  border-radius: 50%;
  box-shadow: 0 2.28px 0 0 #e15b64;
  transform-origin: 45.599999999999994px 46.739999999999995px;
}

.loadingio-spinner-eclipse-46i7dk0kdvt {
  width: 114px;
  height: 114px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.ldio-wag2vezkqai {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-wag2vezkqai div {
  box-sizing: content-box;
}
